import { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { MdDashboard, MdOutlineSell } from "react-icons/md";
import { RiProjectorFill, RiExchangeDollarLine } from "react-icons/ri";
import { FaTools, FaRegCopyright } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
import { BiSolidPurchaseTag, BiTransferAlt } from "react-icons/bi";
import { GrDeploy } from "react-icons/gr";
import { PiSwimmingPool } from "react-icons/pi";
import { MdOutlineToken } from "react-icons/md";
import { button } from "@material-tailwind/react";

export default function MobileBarComponent({ className }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [openToolsMenu, setOpenToolsMenu] = useState(false);
  const [openProjectMenu, setOpenProjectMenu] = useState(false);
  const [openMobileTab, setOpenMobileTab] = useState(false);

  useEffect(() => {
    if (
      location.pathname === "/create-token" ||
      location.pathname === "/set-authority" ||
      location.pathname === "/openbook" ||
      location.pathname === "/manage-lp" ||
      location.pathname === "/token-account"
    ) {
      setOpenToolsMenu(true);
      setOpenProjectMenu(false);
    } else if (
      location.pathname === "/buy" ||
      location.pathname === "/sell" ||
      location.pathname === "/transfer"
    ) {
      setOpenProjectMenu(true);
      setOpenToolsMenu(false);
    }
  }, [location.pathname]);

  const handleCollapse = (e, menuName) => {
    e.stopPropagation();
    if (menuName === "tools") {
      const newOpenToolsMenu = !openToolsMenu;
      setOpenToolsMenu(newOpenToolsMenu);
      if (newOpenToolsMenu) setOpenProjectMenu(false);
    } else if (menuName === "project") {
      const newOpenProjectMenu = !openProjectMenu;
      setOpenProjectMenu(newOpenProjectMenu);
      if (newOpenProjectMenu) setOpenToolsMenu(false);
    }
  };
  const divRef = useRef(null);
  const buttonRef = useRef(null);
  const handleMobileClick = () => {
    setOpenMobileTab(!openMobileTab);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (divRef.current && !divRef.current.contains(event.target) && !buttonRef.current.contains(event.target)) {
        // Click occurred outside the div
        setOpenMobileTab(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <svg
        width={40}
        height={40}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="fixed top-5 left-1 2xl:hidden"
        onClick={handleMobileClick}
        ref={buttonRef}
      >
        {openMobileTab ? (
          <path
            d="M18 6L6 18M6 6l12 12"
            stroke={"currentColor"}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        ) : (
          <path
            d="M3 6h18M3 12h18M3 18h18"
            stroke={"currentColor"}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        )}
      </svg>
      {openMobileTab ? (
        <div
          className={`${className} !m-0 !w-full relative font-sans shadow-md fun_facts_mobile bg-indigo-500 from-[#ffffff0d] to-[#ffffff05] flex-col gap-2 text-white items-center px-5 !block 2xl:!hidden w-full `}
          ref={divRef}
        >
          <div
            className={`2xl:w-full h-10 rounded-lg hover:bg-firstColor flex   justify-start mx-auto  px-3 gap-4 items-center my-2 cursor-pointer ${
              location.pathname === "/dashboard"
                ? "bg-secondColor text-white"
                : ""
            } `}
            onClick={() => {navigate("/dashboard");handleMobileClick()}}
          >
            <MdDashboard className="w-[18px] h-[18px] relative" />
            <div className="text-[1.2rem] font-medium leading-tight  2xl:block">
              Dashboard
            </div>
          </div>
          <div
            className={`2xl:w-full h-10 rounded-lg hover:bg-firstColor flex   2xl:justify-start mx-auto  px-3 gap-4 items-center my-2 cursor-pointer ${
              location.pathname === "/buy" ||
              location.pathname === "/sell" ||
              location.pathname === "/transfer"
                ? "bg-[rgba(255,255,255,0.1)]"
                : ""
            } text-white text-xl`}
            onClick={(e) => handleCollapse(e, "project")}
          >
            <RiProjectorFill className="w-[18px] h-[18px] relative" />
            <div className="flex justify-between w-[calc(100%-40px)] items-center">
              <div className="w-full font-medium leading-tight text-left">
                Project
              </div>
              <IoIosArrowDown
                className={`w-4 h-full ${
                  openProjectMenu ? "transform rotate-180" : ""
                }`}
              />
            </div>
          </div>
          {openProjectMenu && (
            <div className="ml-2">
              <div
                className={`2xl:w-full h-10 rounded-lg hover:bg-firstColor flex   2xl:justify-start mx-auto  px-3 gap-4 items-center my-2 cursor-pointer ${
                  location.pathname === "/buy"
                    ? "bg-secondColor text-white"
                    : ""
                }`}
                onClick={() => {navigate("/buy"); handleMobileClick()}}
              >
                <BiSolidPurchaseTag />
                <div className="   text-base font-medium leading-tight  2xl:flex">
                  Buy
                </div>
              </div>
              <div
                className={` 2xl:w-full h-10 rounded-lg hover:bg-firstColor flex   2xl:justify-start mx-auto  px-3 gap-4 items-center my-2  cursor-pointer ${
                  location.pathname === "/sell"
                    ? "bg-secondColor text-white"
                    : ""
                }  `}
                onClick={() => {navigate("/sell"); handleMobileClick()}}
              >
                <MdOutlineSell />
                <div className="   text-base font-medium leading-tight  2xl:flex">
                  Sell
                </div>
              </div>
              <div
                className={` 2xl:w-full h-10 rounded-lg hover:bg-firstColor flex   2xl:justify-start mx-auto  px-3 gap-4 items-center my-2 cursor-pointer ${
                  location.pathname === "/transfer"
                    ? "bg-secondColor text-white"
                    : ""
                } `}
                onClick={() => {navigate("/transfer"); handleMobileClick();}}
              >
                <BiTransferAlt />
                <div className="   text-base font-medium leading-tight  2xl:flex">
                  Transfer
                </div>
              </div>
            </div>
          )}
          <div
            className={` 2xl:w-full h-10 rounded-lg hover:bg-firstColor flex   2xl:justify-start mx-auto  px-3 gap-4 items-center my-2 cursor-pointer ${
              location.pathname === "/create-token" ||
              location.pathname === "/set-authority" ||
              location.pathname === "/openbook" ||
              location.pathname === "/manage-lp" ||
              location.pathname === "/token-account"
                ? "bg-[rgba(255,255,255,0.1)]"
                : ""
            } text-white text-xl`}
            onClick={(e) => handleCollapse(e, "tools")}
          >
            <FaTools className="w-[18px] h-[18px] relative" />
            <div className="flex justify-between w-[calc(100%-40px)] items-center">
              <div className="w-full font-medium leading-tight text-left">
                Tools
              </div>
              <IoIosArrowDown
                className={`w-4 h-full ${
                  openToolsMenu ? "transform rotate-180" : ""
                }`}
              />
            </div>
          </div>
          {openToolsMenu && (
            <div className="ml-2">
              <div
                className={` 2xl:w-full h-10 rounded-lg hover:bg-firstColor flex   2xl:justify-start mx-auto  px-3 gap-4 items-center my-2 cursor-pointer ${
                  location.pathname === "/create-token"
                    ? "bg-secondColor text-white"
                    : ""
                }`}
                onClick={() =>{ navigate("/create-token"); handleMobileClick()}}
              >
                <GrDeploy />
                <div className="   text-base font-medium leading-tight  2xl:flex">
                  Create SPL Token
                </div>
              </div>
            </div>
          )}
          {openToolsMenu && (
            <div className="ml-2">
              <div
                className={` 2xl:w-full h-10 rounded-lg hover:bg-firstColor flex   2xl:justify-start mx-auto  px-3 gap-4 items-center my-2 cursor-pointer ${
                  location.pathname === "/set-authority"
                    ? "bg-secondColor text-white"
                    : ""
                }`}
                onClick={() => {navigate("/set-authority"); handleMobileClick()}}
              >
                <FaRegCopyright />
                <div className="   text-base font-medium leading-tight  2xl:flex">
                  Set Authority
                </div>
              </div>
            </div>
          )}
          {openToolsMenu && (
            <div className="ml-2">
              <div
                className={` 2xl:w-full h-10 rounded-lg hover:bg-firstColor flex   2xl:justify-start mx-auto  px-3 gap-4 items-center my-2 cursor-pointer ${
                  location.pathname === "/openbook"
                    ? "bg-secondColor text-white"
                    : ""
                }`}
                onClick={() => {navigate("/openbook"); handleMobileClick()}}
              >
                <RiExchangeDollarLine />
                <div className="   text-base font-medium leading-tight  2xl:flex">
                  OpenBook Market
                </div>
              </div>
            </div>
          )}
          {openToolsMenu && (
            <div className="ml-2">
              <div
                className={` 2xl:w-full h-10 rounded-lg hover:bg-firstColor flex   2xl:justify-start mx-auto  px-3 gap-4 items-center my-2 cursor-pointer ${
                  location.pathname === "/manage-lp"
                    ? "bg-secondColor text-white"
                    : ""
                }`}
                onClick={() => {navigate("/manage-lp"); handleMobileClick()}}
              >
                <PiSwimmingPool />
                <div className="   text-base font-medium leading-tight  2xl:flex">
                  Manage LP
                </div>
              </div>
            </div>
          )}
          {openToolsMenu && (
            <div className="ml-2">
              <div
                className={` 2xl:w-full h-10 rounded-lg hover:bg-firstColor flex   2xl:justify-start mx-auto  px-3 gap-4 items-center my-2 cursor-pointer ${
                  location.pathname === "/token-account"
                    ? "bg-secondColor text-white"
                    : ""
                }`}
                onClick={() => {navigate("/token-account"); handleMobileClick();}}
              >
                <MdOutlineToken />
                <div className="   text-base font-medium leading-tight  2xl:flex">
                  Token Account
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
